import "./main.css";
import React, { useState, Suspense } from "react";
import { AuthContext } from "./context/AuthContext";
import { MessageContext } from "./context/MessageContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const About = React.lazy(() => import("./pages/About"));
// import About from "./pages/About";
const Template = React.lazy(() => import("./pages/Template"));
// import Template from "./pages/Template";
const Home = React.lazy(() => import("./pages/Home"));
// import Home from "./pages/Home";
const Project = React.lazy(() => import("./pages/Project"));
// import Project from "./pages/Project";
const ProjectRecords = React.lazy(() => import("./pages/ProjectRecords"));
// import ProjectRecords from "./pages/ProjectRecords";
// import EditProject from "./pages/EditProject";
const EditProject = React.lazy(() => import("./pages/EditProject"));
// import NotFound from "./pages/NotFound";
const NotFound = React.lazy(() => import("./pages/NotFound"));
// import ProtectedRoutes from "./middleware/ProtectedRoutes";
const ProtectedRoutes = React.lazy(() =>
  import("./middleware/ProtectedRoutes")
);
// import NewProject from "./pages/NewProject";
const NewProject = React.lazy(() => import("./pages/NewProject"));
// import ControlPanel from "./pages/ControlPanel";
const ControlPanel = React.lazy(() => import("./pages/ControlPanel"));
// import WebsiteLayout from "./layouts/WebsiteLayout";
const WebsiteLayout = React.lazy(() => import("./layouts/WebsiteLayout"));
// import ScrollToTop from "./hooks/SchroolToTop";
const ScrollToTop = React.lazy(() => import("./hooks/SchroolToTop"));
const Login = React.lazy(() => import("./pages/Login"));
// import Login from "./pages/Login";

function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState([]);

  // Use memo?
  return (
    <Router>
      <Suspense fallback={null}>
        <ScrollToTop />
        <AuthContext.Provider value={{ isAdmin, setIsAdmin }}>
          <MessageContext.Provider
            value={{ feedbackMessage, setFeedbackMessage }}
          >
            <Routes>
              <Route path="/" element={<WebsiteLayout />}>
                <Route index element={<Home />} />
                <Route path=":id" element={<Home />} />
                <Route path="/project/:id" element={<Project />} />
                {/* <Route path="/about" element={<About />} /> */}
                <Route path="/admin/login" element={<Login />} />
                <Route element={<ProtectedRoutes />}>
                  <Route
                    path="/admin/project-records"
                    element={<ProjectRecords />}
                  />
                  <Route path="/admin/project/:id" element={<EditProject />} />
                  <Route path="/admin/new-project" element={<NewProject />} />
                  <Route path="/admin/template" element={<Template />} />
                  <Route
                    path="/admin/control-panel"
                    element={<ControlPanel />}
                  />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Route>
              {/* TODO put in useref */}
              {/* <Route path="*" element={<NotFound />} /> */}
            </Routes>
          </MessageContext.Provider>
        </AuthContext.Provider>
      </Suspense>
    </Router>
  );
}

export default App;
