import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import "./fonts/creato/CreatoDisplay-Black.otf"
import "./fonts/creato/CreatoDisplay-Bold.otf"
import "./fonts/creato/CreatoDisplay-ExtraBold.otf"
import "./fonts/creato/CreatoDisplay-Regular.otf"
import "./fonts/creato/CreatoDisplay-Thin.otf"

import "./fonts/galderglynn/galderglynn-titling-bd.woff2"
import "./fonts/galderglynn/galderglynn-titling-el.woff2"
import "./fonts/galderglynn/galderglynn-titling-lt.woff2"
import "./fonts/galderglynn/galderglynn-titling-rg.woff2"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
